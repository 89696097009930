<template>
    <v-card  height="100%">
        <DlgMessage ref="dlgMsg" @dlgClose="dlgClose"></DlgMessage>
        <div class="pa-3">
          <v-toolbar>
            <v-toolbar-title>Search Redemption</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-breadcrumbs :items="items"></v-breadcrumbs>
          </v-toolbar>
        </div>
        <div class="pa-3">
            <v-card>
                <v-form ref="formSearch">
            <v-row class="mx-1">
                <v-col cols="12" md="4">
                    <v-select
                        v-model="form.search_key"
                        :items="searchKeyList"
                        item-text="description"
                        item-value="code"
                        label="Search Key"
                        standard
                        outlined
                        dense
                        @change="getIndex"
                    ></v-select>
                </v-col>

                
                <v-col cols="12" md="4">
                    <template v-if="searchKeyList[searchKeyIndex]['type'] == 'string'">
                    <v-text-field
                        v-model="form.search_word"
                        label="Search Word"
                        outlined
                        dense
                        clearable
                        :rules="[rules.required, rules.counter]"
                    ></v-text-field>
                    </template>
                    <template v-else>

                            <v-menu v-model="dpicker.menu1" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.start_date"
                                        label="Date From"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.start_date" @change="dpicker.menu1 = false"></v-date-picker>
                            </v-menu>
                            <v-menu v-model="dpicker.menu2" :close-on-content-click="false" max-width="290">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        outlined
                                        dense
                                        :value="form.end_date"
                                        label="Date To"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.end_date" @change="dpicker.menu2 = false"></v-date-picker>
                            </v-menu>
                    </template>

                </v-col>
                <v-col cols="12" md="3">
                    <v-select
                        v-model="form.data_limit"
                        :items="dataLimitList"
                        item-text="description"
                        item-value="code"
                        label="Data Limit"
                        standard
                        outlined
                        dense
                    ></v-select>
                </v-col>
                <v-col cols="12" md="1">
                    <v-btn icon condensed color="panel1" @click="refreshDataTable">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </v-col>
                
            </v-row>
                </v-form>
            </v-card>
        </div>
        <div class="pa-3">
        <v-card>
        <v-card-title>
        <v-text-field
            v-model="tblSearch"
            append-icon="mdi-magnify"
            label="Search data below"
            hide-details
            outlined
        ></v-text-field>
        </v-card-title>
        <v-card-title>
            <DlgExportExcel ref="dlgExportExcel" :tblHeaders="tblHeaders" :tblBody="tblBody" filename="redemption_search_list" bookType="xlsx"></DlgExportExcel>
        </v-card-title>        
        <v-data-table
        item-key="process_reference_no"
        :headers="tblHeaders"
        :items="tblBody"
        :search="tblSearch"
        :loading = "loadingFlag"
        loading-text="Loading... Please wait"
        @click:row="openDetail"
        ></v-data-table>
        </v-card>
        </div>

    </v-card>
</template>
<script>
import moment from 'moment'
import DlgMessage from '@/components/DlgMessage'
import ApRedemptionService from "@/services/ApRedemptionService"
import DlgExportExcel from '@/components/DlgExportExcel'
export default {
    
    components:{DlgMessage,DlgExportExcel},
    data() {
        return{
            //breadcrumb
            items: [
              {text:'Redemptions',disabled: false,href:'#',},
              {text:'Search',disabled: false,href:'#',},
              ],
            tblHeaders:[
                { text:"Redemption ID",value: "redemption_id"  },
                { text:"Date.", value:"redemption_date" },                
                { text:"Name", value:"full_name" },
                { text:"State", value:"state" },
                { text:"Cell Phone #", value:"cell_phone" },
                { text:"Status", value:"redemption_status" },
                { text:"Total Pts", value:"total_points" },
                { text:"Total Item", value:"total_item" },
                { text:"Total Qty", value:"total_quantity" },
            ],

            tblBody:[],
            tblSearch:'',
            searchKey:'redemption_date',
            searchKeyIndex:0,
            searchKeyList:[
                {code:'redemption_date',description:'Redemption Date',type:'date'},
                {code:'created_date',description:'Created Date',type:'date'},
                {code:'login_id',description:'Member ID',type:'string'},
                {code:'full_name',description:'Name',type:'string'},
                {code:'cell_phone',description:'Mobile Phone',type:'string'},
                {code:'process_reference_no',description:'Process Reference No',type:'string'},
                
            ],

            dataLimit:500,
            dataLimitList:[
                {code:500,description:'500 rows'},
                {code:1000,description:'1000 rows'},
                {code:2000,description:'2000 rows'},
                {code:5000,description:'5000 rows'},
            ],
            dpicker:{menu1: false,menu2: false,},
            loadingFlag:false,
            rules: {
                required: value => !!value || 'Required.',
                counter: value => value.length >= 3 || 'Min 3 characters',
            },
            form:{
                login_id:"",
                search_key:"redemption_date",
                search_word:"",
                start_date:"",
                end_date:"",
                data_limit:500
            }
        }
    },
    async created(){
        this.form.start_date = moment(String(new Date())).format('YYYY-MM-01');
        this.form.end_date = moment(String(new Date())).format('YYYY-MM-DD');
    },
    methods:{
            async refreshDataTable(){
                if(this.$refs.formSearch.validate()){
                    this.loadingFlag = true
                    try{
                    ApRedemptionService.search_redemption(this.form).then((res) => {
                        //console.log(res);
                        this.tblBody = res.data.data;
                        this.loadingFlag = false;
                        this.errorFlag = false;
                    }).catch((e)=>{
                        //console.log(e.response);
                        this.$refs.dlgMsg.showDlgBox(true,'error','Transaction List',e.response.data.error_message);
                        this.loadingFlag = false
                        this.errorFlag = true;
                    })
                    ;
                } catch(e) {
                    this.loadingFlag = false
                    this.$refs.dlgMsg.showDlgBox(true,'error','Merchant','Unknown Error');
                  
                }          
                }
            },
            getIndex(e){
                this.searchKeyIndex = this.searchKeyList.map(function (key) { return key.code; }).indexOf(e);
            },
            openDetail(value){
                let routeData = this.$router.resolve({ 
                    name: 'redemption_details', 
                    params: { 
                        process_reference_no: value.process_reference_no,
                    } 
                })
                window.open(routeData.href,'_blank')
                // this.$router.push({ name: 'point_transaction_details', params: { process_reference_no: value.process_reference_no } })
            },
            dlgClose(){
                this.visible = false;        
            },
    }
}
</script>